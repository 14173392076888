import {
  Alert,
  Avatar,
  Container,
  CssBaseline,
  Grid,
  Link,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Login } from "@mui/icons-material";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import FieldInput from "../form-fields/FieldInput";
import SubmitBtn from "../SubmitBtn";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { authLogin } from "../../ducks/auth";
import { Link as RouterLink } from "react-router-dom";

const schema = Joi.object({
  email: Joi.string().min(4).required(),
  password: Joi.string().min(4).required(),
});

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    errors[FORM_ERROR] = "email หรือ password ไม่ถูกต้อง";
  }

  return errors;
};

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/";

  const onSubmit = (formValues) => {
    return dispatch(authLogin(formValues))
      .then(() => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      })
      .catch(({ response }) => {
        if (response.status === 429) {
          return {
            [FORM_ERROR]:
              "พบการเรียกใช้งานระบบเป็นจำนวนมาก กรุณากลับมาใช้งานใหม่ในอีก 5 นาที",
          };
        } else if (response.status >= 400 && response.status < 500) {
          return {
            [FORM_ERROR]: "email หรือ password ไม่ถูกต้อง",
          };
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Typography component="h1" variant="h4">
          Bank Portal
        </Typography> */}
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          เข้าสู่ระบบ
        </Typography>
        <Form validate={validate} onSubmit={onSubmit}>
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            return (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                {submitFailed && (error || submitError) && (
                  <Alert severity="warning" sx={{ width: "100%" }}>
                    {error || submitError}
                  </Alert>
                )}
                <FieldInput
                  name="email"
                  label="Email"
                  required
                  controlProps={{ fullWidth: true, margin: "normal" }}
                />
                <FieldInput
                  name="password"
                  label="Password"
                  required
                  controlProps={{
                    fullWidth: true,
                    margin: "normal",
                    type: "password",
                  }}
                  inputType="password"
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                {/* <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
                  <AlertTitle>เพื่อโปรดทราบ</AlertTitle>
                  xxxxxxx
                </Alert> */}
                <SubmitBtn
                  variant="contained"
                  submitting={submitting}
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<Login />}
                >
                  เข้าสู่ระบบ
                </SubmitBtn>
                <Grid container>
                  <Grid item xs>
                    <Link
                      component={RouterLink}
                      to="/forgotpassword"
                      variant="body2"
                    >
                      ลืมรหัสผ่าน?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/register" variant="body2">
                      ลงทะเบียนใช้งาน
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        </Form>
      </Box>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
  );
}
